import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Container, Header } from 'semantic-ui-react'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const SimplePageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <Container text style={{ margin: '40px 0' }}>
      <Header as="h2">{title}</Header>
      <PageContent className="content" content={content} />
    </Container>
  )
}

SimplePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
}

const SimplePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <SimplePageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

SimplePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default SimplePage

export const simplePageQuery = graphql`
  query PagePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
